(function (win, $) {
    'use strict';
    var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
        home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

    fp.home = (function () {
        var getScreenSize = function () {
            var pixelDensity = window.devicePixelRatio || 1;
            var width = window.innerWidth * pixelDensity;

            //console.log("Pixel Density: " + pixelDensity + " Width: " + width);

            if (width >= 2400) {
                return 'xlarge';
            }
            if (width >= 1400) {
                return 'large';
            }
            if (width >= 1000) {
                return 'medium';
            }
            if (width >= 600) {
                return 'small';
            }
            return 'xsmall';
        };

        return {
            init: function () {
                var initialLoad = true;
                $('.homepage-link').addClass('selected');

                // Set slideshow height to window height
                function jqUpdateSize() {
                    var height = $(window).height();
                    var announcementHeight = $('.message-banner').outerHeight();

                    if ($('#width-detection').css('float') == 'left') {
                        // left === greater than screen-md
                        // right === greater than screen-sm
                        // none === less than screen-sm
                        $('.slideshow').css('height', height - announcementHeight);
                    } else {
                        var headerHeight = $('#utility').outerHeight() + $('#header').outerHeight();
                        $('.slideshow').css('height', height - headerHeight - announcementHeight);
                    }

                    if (initialLoad === true) {
                        $('body').removeClass('fade-home-contents');
                        initialLoad = false;
                        // This, along with some CSS, helps smooth out scrolling on small screens
                        setTimeout(function () {
                            $('body').addClass('hero-set');
                        }, 1000);
                    }
                }

                jqUpdateSize();

                $(window).resize(jqUpdateSize);
            },

            setBgImage: function (imgInfo) {
                var screenSize = getScreenSize();
                var filteredImages = imgInfo.filter(function (img) {
                    return img.assetSize === screenSize;
                });
                if (filteredImages.length === 0) {
                    return;
                }

                var randomNumber = Math.floor(Math.random() * filteredImages.length);
                var randomBackgroundImage = filteredImages[randomNumber];

                var slideshowEl = document.getElementsByClassName("slideshow")[0];
                slideshowEl.style.backgroundImage = "url('" + randomBackgroundImage.imagePath + "')";
                if (randomBackgroundImage.altText) {
                    slideshowEl.setAttribute("aria-label", randomBackgroundImage.altText);
                    slideshowEl.setAttribute('role', 'img');
                }
            }
        };
    })();
})(window, $);